import React from "react";
import { Link } from "react-router-dom";
import "./Main.css";

const Main = () => {
    return (
        <div className='main__bg'>
            <div className='main__frame'>
                <h1 className='main__title'>Planero Web</h1>
                <p className='main__subtitle'>ALTERNATIVAS DE DESARROLLO PLAN GENERAL DE CATARROJA</p>
                <Link to="/ALTERNATIVA_0">
                <button className='main__bt'>Accede</button>
                </Link>
                <p className='main__geoinnova'>Más información <a href="https://geoinnova.org/">Geoinnova</a></p>      
                {/* popup  */}
                <div id="popup" className="ol-popup">
                    <button id="popup-closer" type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <div id="popup-content"></div>
                </div>
            </div>

        </div>
    )
}
export default Main;