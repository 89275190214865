import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";

import OlMap from "ol/Map";
import OlView from "ol/View";
import OlLayerTile from "ol/layer/Tile";
import XYZ from 'ol/source/XYZ';

import TileLayer from 'ol/layer/Tile';
import TileWMS from 'ol/source/TileWMS';

import OlLayerImage from "ol/layer/Image";
import OlImageStatic from "ol/source/ImageStatic";
import OlLayerGroup from "ol/layer/Group";
import ImageWMS from "ol/source/ImageWMS";


import {
  defaults as defaultControls,
  // OverviewMap,
  ScaleLine,
} from "ol/control";

import {
  MapComponent,
  LayerTransparencySlider,
  SimpleButton,
  MeasureButton,
  ToggleGroup,
  LayerSwitcher,
  // LayerTree,
  // ToggleButton,
} from "@terrestris/react-geo";

import MultiLayerSlider from '@terrestris/react-geo/dist/Slider/MultiLayerSlider/MultiLayerSlider';
import { PageHeader } from "antd";

import MapIndex from "../MapIndex/MapIndex";
import Legend from "../Legend/Legend";
import About from "../About/About";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRuler, faDrawPolygon } from "@fortawesome/free-solid-svg-icons";

import "ol/ol.css";
import "./Map.css";

import logo from "./logo.png";

//erb /********************************************* */
import { Attribution } from 'ol/control';
import Overlay from 'ol/Overlay';
// import geoJsonData from "../../data/geojson/geoJson";
// import GeoJSON from 'ol/format/GeoJSON';

import { Circle as CircleStyle, Fill, Stroke, Style } from 'ol/style';
import { get } from 'ol/proj';
import { OSM, Vector as VectorSource } from 'ol/source';
import { Vector as VectorLayer } from 'ol/layer';

const mapDivId = `map-${Math.random()}`;
let selected = null;

// Popup
let container, popup, closer, contentPopup;

//para controlar si se pulsan botones medición
let toggleLineal = false;
let togglePolygon = false;
//para controlar si se pulsan boton cierre popup
let isPressCloseButtonPopup = false;


/*************************************************** */

const scalineControl = new ScaleLine({
  units: "metric",
  bar: true,
  steps: 2,
  text: false,
  minWidth: 100,
  position: "left",
});

const Map = (data) => {
  // const [visibleIndex, setVisibleIndex] = useState(false);



  const item = data.data;
  const dataUrl = `./data/${item.type}/${item.object}`;
  const pdfUrl = `./data/pdf/${item.object}.pdf`;
  const geoJsonUrl = '';
  const center = item.center;
  const extent = item.extent;
  const zoom = item.zoom;
  const minZoom = item.minZoom;
  const maxZoom = item.maxZoom;




  /**
 * Carga capa de tipo Image o Tile según valor type
 * @param  {String} type Tipo de capa según item (tile o img)
 * @return {ol layer}     OpenLayers layer
 */
  function draw_geoImage(type) {
    let layer = new OlLayerImage({
      name: "Villava",
      source: new OlImageStatic({
        url: `${dataUrl}.png`,
        attributions: ":: Desarrollo Geoinnova",
        projection: "EPSG:3857",
        alwaysInRange: true,
        imageExtent: item.extent,
      }),
    });
    let layerTile = new OlLayerTile({
      name: item.title,
      title: item.title,
      extent: extent,
      source: new XYZ({
        attributions: item.attributions,
        minZoom: minZoom,
        maxZoom: maxZoom,
        url: `${dataUrl}/{z}/{x}/{-y}.png`,
        tileSize: [256, 256],
      }),
    });

    if (type === 'tile') {
      return layerTile
    } else {
      return layer
    }
  }

  const geoimage = draw_geoImage(item.type)

  const pnoa =  new TileLayer({
    name: 'Base PNOA',
    crossOrigin: "anonymous",
    attributions:
          'Base © <a href="https://tms-pnoa-ma.idee.es/1.0.0/pnoa-ma/{z}/{x}/{-y}.jpeg/' +
           '">PNOA</a>',
    source: new XYZ({
              url:  'https://tms-pnoa-ma.idee.es/1.0.0/pnoa-ma/{z}/{x}/{-y}.jpeg',
            }),
  });

  // const catastro = new OlLayerImage({
  //   name: 'Catastro',
  //   extent: extent,
  //   source: new ImageWMS({
  //     url: "https://inspire.navarra.es/services/CP/wms",
  //     crossOrigin: "anonymous",
  //     attributions:
  //       'Base © <a href="http://www.geo.admin.ch/internet/geoportal/' +
  //       'en/home.html">PNOA</a>',
  //     params: { LAYERS: "CP:CadastralParcel" },
  //     //serverType: "mapserver",
  //   }),
  // });


  const catastro = new TileLayer({
    name: 'catastro',
    title: 'Servicio WMS Catastro',
    source: new TileWMS({
        url: 'http://ovc.catastro.meh.es/Cartografia/WMS/ServidorWMS.aspx?',

        params: {
            LAYERS: "Catastro",
            TILED: "true",
            VERSION: "1.1.1",
        },
    }),
    visible: false
});

  const osm = new OlLayerTile({
    name: 'Base OSM',
    extent: extent,
    source: new OSM(),
  })

  // erb /********************************** */
  const attribution = new Attribution({
    collapsible: true,
  });

  //configuración del icono y color según característica
  const selectIcon = (feature) => {
    let bgColors = {
      35100018: 'blue', // sifonico 6aff00
      35100019: 'red',     // arqueta
      35000023: 'green',
      35100004: '#d1ba5e',
    };

    const codigo = feature.get('Codigo');

    const myStyle = new Style({
      image: new CircleStyle({
        radius: 5,
        fillColor: '#da2122',
        color: '#000000',
        dashArray: '',
        opacity: '1.0',
        fillOpacity: '1.0',
        stroke: new Stroke({ color: 'white', width: 1 }),
        fill: new Fill({ color: bgColors[codigo] })
      }),
    });
    return myStyle;
  }

  // ------------------
  const [vectorData, setvectorData] = useState([]);

  const getVectorData = () => {
    fetch('./data/geojson/infraestructuras.json'
      , {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      }
    )
      .then(function (response) {
        console.log(response)
        return response.json();
      })
      .then(function (myJson) {
        console.log(myJson);
        setvectorData(myJson)
      });
  }
  // getVectorData()
  // useEffect(()=>{
  //   getVectorData()
  // },[])

  //------------------- GEOJSON

  // console.log(geoJsonData);
  // //obtención de los puntos desde una constante geoJson
  // const vectorSource = new VectorSource({
  //   features: new GeoJSON()
  //   .readFeatures(geoJsonData, { featureProjection: get('EPSG:3857') }),
  // });

  // const vectorLayer = new VectorLayer({
  //   name: 'Info xample',
  //   source: vectorSource,
  //   style: selectIcon,
  // });

  /*
    //obtención de los puntos desde un geoJson externo
    const vectorLayer = new VectorLayer({
        name: 'vector Layer nuevo',
        source: new VectorSource({
            format: new GeoJSON(),
            //url: './data/4_1_1.js'
            //url: 'http://visores.geoinnova.org/planero-react/4_1_1.js'
            //url: 'https://gist.githubusercontent.com/neogis-de/154f4bd155f77e0f3689/raw/5a1642fac4afff463c3ff08beaad55892fe9acd4/geojson.js'
        }),
        style: selectIcon,
    });
  */


  /**************************** */
  geoimage.setVisible(true);
  // catastro.setVisible(false);
  //vectorLayer.setVisible(false);


  const baselayerGroup = new OlLayerGroup({
    name: "baselayerGroup",
    layers: [osm, pnoa],
  });


  // Mapa
  var map = new OlMap({
    controls: defaultControls({
      attribution: false,
      zoom: false,
      rotate: true,
    }).extend([scalineControl]),

    view: new OlView({
      center: center,
      projection: "EPSG:3857",
      zoom: zoom,
      minZoom: minZoom,
      maxZoom: maxZoom,
    }),
    layers: [baselayerGroup, geoimage, catastro /*, vectorLayer*/],
    //controls: defaultControls({ attribution: false}).extend([attribution]),
  });

  const zoomIn = () => {
    console.log("zoomIn");
    map.getView().setZoom(map.getView().getZoom() + 0.5);
  };

  const zoomOut = () => {
    console.log("zoomOut");
    map.getView().setZoom(map.getView().getZoom() - 0.5);
  };

  const zoomInit = () => {
    map.getView().setCenter([center[0], center[1]]);
    map.getView().setZoom(16);
  };

  // Erb
  // Activar/desactivar capas catastro(WMS) 
  const catastroLayerShow = () => {
    isPressCloseButtonPopup = false;
    cerrarPopup();
    catastro.getVisible() ? catastro.setVisible(false) : catastro.setVisible(true);

  };

  const mostrarDatosCatastro = (evt) => {
    if (contentPopup == null) contentPopup = document.getElementById('popup-content');
    let url = catastro.getSource().getFeatureInfoUrl(
      evt.coordinate,
      map.getView().getResolution(),
      'EPSG:3857', { 'INFO_FORMAT': 'application/json' }
      // 'EPSG:3857', { 'INFO_FORMAT': 'text/html' }
    );

    console.log(url)
    let urlProxy ="https://corsanywhere.herokuapp.com/"+url;
    if (urlProxy) {
      
      fetch(urlProxy)
        .then(function (response) { return response.text(); })
        .then(function (responseText) {
          
          contentPopup.innerHTML = '';
          popup.setPosition(evt.coordinate);
          // si el nivel de zoom es superior a 14.80 se activa la referencia catastral
          if (getZoomView(map) > 14.80) {
            contentPopup.innerHTML = '<div class="erb-catastro--title"> Información del Catastro</div>';
            contentPopup.innerHTML += '<table class="erb-info-layer">' +
              '<tr><td>' + responseText + '</td></tr>';
             
            contentPopup.innerHTML += '</table>';
            // setTimeout(cerrarPopup,3000);
          } else {
            contentPopup.innerHTML += '<div class="erb-table-wms--alert"> ¡¡ No existen datos para este nivel de zoom !!</div>';
          }
        });
    }
  }

  const mostrarDatosInfoLayer = (feature) => {
    if (contentPopup == null) contentPopup = document.getElementById('popup-content');
    contentPopup.innerHTML = '<div class="erb-catastro--title"> Información de la Capa</div>';
    contentPopup.innerHTML += '<table class="erb-info-layer"><tr><td>Capa</td><td>' + feature.get('layer') + '</td></tr><tr><td>Tipo</td><td>' + feature.get('Tipo') + '</td></tr>';
    contentPopup.innerHTML += '</table>';
  }




  //Interacciones eventos sobre el mapa
  map.on('pointermove', function (e) {
    if (selected !== null) {
      // selected.setStyle(highlightStyle);
      selected = null;
    }

    map.forEachFeatureAtPixel(e.pixel, function (f) {
      selected = f;
      // f.setStyle(highlightStyle);
      return true;
    });

    if (selected) {
      // console.log('&nbsp;Hovering: ' + selected.get('codtipo'));
      this.getTargetElement().style.cursor = 'pointer';
    } else {
      this.getTargetElement().style.cursor = '';

    }
  });


  map.on('singleclick', function (evt) {
    // solo mostramos los puntos si la capa vectorLayer está activa y no está pulsa
    // la herramienta de medición
      if (catastro.getVisible() && !toggleLineal && !togglePolygon) {
      //control del click, si viene de cerrar el popup no muestra nada
      if (isPressCloseButtonPopup) {
        isPressCloseButtonPopup = false;
      }
      else {
        map.addOverlay(popup);
        mostrarDatosCatastro(evt);
      }

    }
    closer.onclick = () => {
      isPressCloseButtonPopup = true;
      cerrarPopup();
    }
  });


  return (
    <div>
      <PageHeader
        className="site-page-header"
        style={{ position: "absolute", width: "100%", height: "125px" }}
        title={item.title}
        // subTitle="(Demo)"
        avatar={{ src: logo }}
      // extra={[
      //     <Link to="/">
      //         <Button key="1" type="primary">Principal</Button>
      //     </Link>,
      //     <Button key="2" type="primary">Ayuda</Button>,
      // ]}
      />

      <MapComponent map={map}>

        <LayerTransparencySlider
          style={{
            zIndex: "1",
            position: "absolute",
            top: "90px",
            left: "110px",
            width: "200px",
          }}
          className="erb-transparencySlider"
          layer={geoimage}
        />
      </MapComponent>
      {/* -----Zoom init */}
      <SimpleButton
        style={{ position: "fixed", top: "90px", left: "30px" }}
        onClick={zoomInit}
        shape="circle"
        iconName="home"
        tooltip="Zoom inicial"
        tooltipPlacement="right"
        size="medium"
      />
      <SimpleButton
        style={{ position: "fixed", top: "130px", left: "30px" }}
        onClick={zoomIn}
        shape="square"
        iconName="plus"
        tooltip="Aumentar Zoom"
        tooltipPlacement="right"
        size="medium"
      />
      <SimpleButton
        style={{ position: "fixed", top: "160px", left: "30px" }}
        onClick={zoomOut}
        shape="square"
        iconName="minus"
        tooltip="Disminuir Zoom"
        tooltipPlacement="right"
        size="medium"
      />
      <SimpleButton
        style={{ position: "fixed", top: "279px", left: "30px" }}
        href={pdfUrl}
        target="blank"
        shape="circle"
        // icon="bars"
        iconName='download'
        tooltip="Descargar original"
        tooltipPlacement="right"
        size="medium"
      />

      <ToggleGroup allowDeselect={true}>
        {/* -----Legend */}
        <Legend
          top="206px"
          title={item.title}
          imgLegend={item.imgLegend}
          imgLegendGeneral={item.imgLegendGeneral}
          authors={item.authors}
        />

        <MapIndex top="270px" />
      </ToggleGroup>

      <ToggleGroup allowDeselect={true}>
        <MeasureButton
          style={{ position: "fixed", top: "316px", left: "30px" }}
          shape="circle"
          name="steps"
          map={map}
          measureType="line"
          showMeasureInfoOnClickedPoints
          tooltip="Medición distancia"
          tooltipPlacement="right"
          continueLineMsg="Click para añadir segmento"
          onToggle={pulsaMedicionLineal}
        >
          <span>
            <FontAwesomeIcon icon={faRuler} />
          </span>
        </MeasureButton>
        <MeasureButton
          style={{ position: "fixed", top: "353px", left: "30px" }}
          shape="circle"
          name="poly"
          map={map}
          measureType="polygon"
          tooltip="Medición área"
          tooltipPlacement="right"
          continuePolygonMsg="Click para añadir nodo"
          showMeasureInfoOnClickedPoints
          onToggle={pulsaMedicionPolygon}
        >
          <span>
            <FontAwesomeIcon icon={faDrawPolygon} />
          </span>
        </MeasureButton>
      </ToggleGroup>

      <About top="430px" map={map} />

      <SimpleButton
        style={{ position: "fixed", top: "436px", left: "30px" }}
        onClick={catastroLayerShow}
        shape="circle"
        iconName='building'
        tooltip="Mostrar capa de catastro"
        tooltipPlacement="right"
        size="medium"
      />


      <div
        id={mapDivId}
      >
        <LayerSwitcher
          // style={{
          //   position: 'absolute',
          //   bottom: 0,
          //   right: '10px',
          //   zIndex: 999999
          // }}
          map={map}
          layers={[osm, pnoa]}
        />
      </div>

      {/* popup  */}
      {/* <div id="popup" className="ol-popup">
        <button id="popup-closer" type="button" className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <div id="popup-content"></div>
      </div> */}

    </div>
  );

};

const pulsaMedicionLineal = () => {
  toggleLineal === true ? toggleLineal = false : toggleLineal = true;
  cerrarPopup();
}

const pulsaMedicionPolygon = () => {
  togglePolygon === true ? togglePolygon = false : togglePolygon = true;
  cerrarPopup();
};

const cerrarPopup = () => {
  if (popup !== undefined) {
    popup.setPosition(undefined);
    if (closer !== null) closer.blur();
    return false;
  }

}

// devuelve el nivel de zoom de la vista
const getZoomView = (map) => {
  return map.getView().getZoom();
}

document.addEventListener("DOMContentLoaded", function (event) {
  console.log("Cargado:" + document.getElementById('popup'));
  // // Popup

  container = document.getElementById('popup');
  contentPopup = document.getElementById('popup-content');
  closer = document.getElementById('popup-closer');

  popup = new Overlay({
    element: container,
    positioning: 'bottom-center',
    stopEvent: false,
    autoPanAnimation: true,
    autoPan: true,
    offset: [0, -40],
    position: undefined,
  });
  cerrarPopup();

});

export default Map;
