import React, { useState} from "react";
import { Drawer } from "antd";
import { Link } from "react-router-dom";
import { SimpleButton } from "@terrestris/react-geo";
import mapslist  from "../../data";

// import MapsRepository from "../../services/MapsRepository";

import './MapIndex.css'

const MapIndex = (props) => {

  const [visible, setVisible] = useState(true);

  // const [mapslist2, setMapsList] = useState();

  // const mapsRepo = new MapsRepository()
  // const listado = mapsRepo.searchAll()

  // useEffect(() => {
    
  //   setMapsList(mapsRepo)
  // }, [mapslist2]);

  const toggleDrawer = () => {
    setVisible(!visible);
  };


  return (
    <div>
      <SimpleButton
        style={{ position: "fixed", top:"242px", left: "30px" }}
        onClick={toggleDrawer}
        shape="circle"
        // icon="bars"
        iconName="bars"
        tooltip="Panel de índice"
        tooltipPlacement="right"
        size="medium"
      />
      <Drawer
        title="Índice"
        placement="right"
        onClose={toggleDrawer}
        visible={visible}
        mask={false}
        style={{ position: "absolute" }}
        width={window.innerWidth > 400 ? 400 : 300}
      >
        {/* <h2>Anexo planimetría</h2> */}
        <ul style={{ "list-style": "none" }}>
          {mapslist.map((item) => (
            <li key={item.id}>
              <Link to={item.path}>{item.title}</Link>
              {/* <a href={item.path}>{item.title}</a> */}
            </li>
          ))}
        </ul>
      </Drawer>
    </div>
  )
}


export default MapIndex;