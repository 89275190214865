const autores1 = [
  "Director/Coordinador: José Manuel Fernandino",
  "Coordinador Ambiental: Luis Quesada Muelas (Geógrafo Col. 2.312)",
  "Abogada: Carmen Teresa Benlloch Casabán",

  "Equipo Técnico: Prueba",

  "Dirección Técnica: Luis Quesada Muelas (Geógrafo Col. 2.312)",
  "Edición Cartográfica: Enrique López Rodríguez (Doctor en Geografía)",
]

// Indicar carpeta raiz '' o subdirectorio '/subdirectorio'
//const baseRoute = "";
const baseRoute = "";

const mapslist = [
  {
    id: 1,
    "path": baseRoute+"/ALTERNATIVA_0",
    object: "ALTERNATIVA_0",
    title: "ALTERNATIVA 0",
    subtitle: "",
    type: "tile",
    scale: 30000,
    date: "",
    center: [-44625,4778063],
    extent: [
      -50683.6935542709106812,4773585.8038685470819473, -38083.6935414203471737,4782495.8038776339963078,
    ],
    attributions: "Ayuntamiento de Catarroja",
    imgLegendGeneral: "Leyenda_clasificacion",
    textLegendGeneral: "",
    imgLegend: "",
    textLegend: "",
    table: "",
    textTable: "",
    zoom: 14,
    minZoom: 14,
    maxZoom: 18,
    addvector: false,
    cadastre: "spain",
    base: [],
    authors: autores1,
  },
  {
    id: 2,
    "path": baseRoute+"/ALTERNATIVA_1",
    object: "ALTERNATIVA_1",
    title: "ALTERNATIVA 1",
    subtitle: "",
    type: "tile",
    scale: 30000,
    date: "",
    center: [-44625,4778063],
    extent: [
      -50690.3848177721592947,4773585.4102124515920877, -38090.3848049216030631,4782495.4102215385064483,
    ],
    attributions: "Ayuntamiento de Catarroja",
    imgLegendGeneral: "Leyenda_clasificacion",
    textLegendGeneral: "",
    imgLegend: "",
    textLegend: "",
    table: "",
    textTable: "",
    zoom: 14,
    minZoom: 14,
    maxZoom: 18,
    addvector: false,
    cadastre: "spain",
    base: [],
    authors: autores1,
  },
  {
    id: 3,
    "path": baseRoute+"/ALTERNATIVA_2",
    object: "ALTERNATIVA_2",
    title: "ALTERNATIVA 2",
    subtitle: "",
    type: "tile",
    scale: 30000,
    date: "",
    center: [-44625,4778063],
    extent: [
      -50690.3848177721592947,4773585.4102124515920877, -38090.3848049216030631,4782495.4102215385064483,
    ],
    attributions: "Ayuntamiento de Catarroja",
    imgLegendGeneral: "Leyenda_clasificacion",
    textLegendGeneral: "",
    imgLegend: "",
    textLegend: "",
    table: "",
    textTable: "",
    zoom: 14,
    minZoom: 14,
    maxZoom: 18,
    addvector: false,
    cadastre: "spain",
    base: [],
    authors: autores1,
  },
];

export default mapslist;
