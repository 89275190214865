import { BrowserRouter, Route } from "react-router-dom";

import Main from "./components/Main/Main";
import Map from "./components/Map/Map";

import "ol/ol.css";
// import "antd/dist/antd.css";
import "./geoinnova.css";
import "./App.css";

import mapslist from "./data.js"; // data.js



function App() {
  return (
    <div className="App">
      <BrowserRouter basename="/">
        <Route path="/" exact render={Main} />
        {mapslist.map((item) => (
          <Route path={item.path}>
            <Map key={item.id} data={item}></Map>
          </Route>
        ))}
      </BrowserRouter>
    </div>
  );
}

export default App;
