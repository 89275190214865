import React, { useState } from "react";
import { Drawer, Card } from "antd";
import { SimpleButton } from "@terrestris/react-geo";

function About(props) {

  const [visible, setVisible] = useState(false);
  const toggleDrawer = () => {
    setVisible(!visible);
  };
  // console.log(props);
  return (
    <div>
      <SimpleButton
        style={{ position: "fixed", top: "400px", left: "30px" }}
        onClick={toggleDrawer}
        shape="circle"
        iconName="question"
        tooltip="Sobre..."
        tooltipPlacement="right"
        size="medium"
      />
      <Drawer
        title="Planero Digital"
        placement="right"
        onClose={toggleDrawer}
        visible={visible}
        mask={false}
        style={{ position: "absolute" }}
        width="400px">

        <Card size="small" title="Información">

          <p>
            Se presentan las 3 Alternativas de modelo territorial del municipio de Catarroja. Estos modelos son propuestas a grandes rasgos, dado el carácter estructural del Plan General. El usuario podrá interactuar con las diferentes alternativas y descargarse los planos.
          </p>

          <p>
            De esta forma y mediante la consulta cruzada sobre el propio Documento de Consulta Previa que explica dichas alternativas, el usuario podrá valorar y participar en el desarrollo del Plan General Estructural, mediante registro general.
          </p>
          <p>
            Puede acceder a los Documentos de Consulta Previa desde los siguientes enlaces:
          </p>
          <a href="https://pgecatarroja.geoinnova.org/pdf/DOCUMENTO_DE_CONSULTA_PREVIA_corregido.pdf" target="_blank" rel="noopener noreferrer" class="btn btn-md btn-skyblue blue-hover"><button class="btn ">Documento de Consulta Previa</button></a>
          <a href="https://pgecatarroja.geoinnova.org/pdf/DOCUMENT_DE_CONSULTA_PREVIA_valencia.pdf" target="_blank" rel="noopener noreferrer" class="btn btn-md btn-skyblue blue-hover"><button class="btn ">Documento de Consulta Previa (Ver. Valenciano) </button></a>
          <a href="https://www.dropbox.com/s/nxnw62hjjf9umxx/DOCUMENTO%20DE%20CONSULTA%20PREVIA-VERSION%20EXTENDIDA_F.pdf?dl=0" target="_blank" rel="noopener noreferrer" class="btn btn-md btn-skyblue blue-hover"><button class="btn ">Documento de Consulta Previa (Ver. Extendida)</button></a>
          <a href="https://pgecatarroja.geoinnova.org/pdf/Edicto_consulta_previa.pdf" target="_blank" rel="noopener noreferrer" class="btn btn-md btn-skyblue blue-hover"><button class="btn ">Edicto de Consulta Previa</button></a>
        </Card>
      </Drawer>
    </div>
  );
}

export default About;
