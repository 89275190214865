import React, { useState } from "react";
import { Drawer, Card } from "antd";
import { SimpleButton } from "@terrestris/react-geo";

function Legend(props) {
  const symbologyImgGeneral = `./data/legend/${props.imgLegendGeneral}.jpg`;
  const symbologyImg = `./data/legend/${props.imgLegend}.jpg`;
  const authorsList = props.authors;


  const [visible, setVisible] = useState(false);
  const toggleDrawer = () => {
    setVisible(!visible);
  };

  return (

    <div>
      <SimpleButton
        style={{ position: "fixed", top: props.top, left: "30px" }}
        onClick={toggleDrawer}
        shape="circle"
        // icon="info"
        iconName="info"
        tooltip="Ver leyenda"
        tooltipPlacement="right"
        size="medium"

      />
      <Drawer
        title="Leyenda"
        placement="right"
        onClose={toggleDrawer}
        visible={visible}
        mask={false}
        style={{ position: "absolute" }}
        width={window.innerWidth > 400 ? 400 : 300}
      >
        <Card size="small" title="Título">
          <p><b>{props.title}</b></p>
        </Card>
        <div style={{ paddingTop: "15px" }}>
          <Card size="small" title="Leyenda" >
            <img width="50%" alt="Symbology" src={symbologyImgGeneral} />
          </Card>
        </div>
        {/* <div style={{ paddingTop: "15px" }}>
          <Card size="small">
            <img width="100%" alt="Symbology" src={symbologyImg} />
          </Card>
        </div> */}
        {/* <div style={{ paddingTop: "15px" }}>
          <Card size="small" title="Redactores">
            <p />
            {authorsList.map((item) => (
              <p key={item} style={{ "line-height": "25px" }}>{item}</p>
            ))}
          </Card>

        </div> */}
        <div style={{ paddingTop: "15px" }}>
          <Card size="small" title="Redactores">
            <div class="equipo">
              <ul>
                <li><strong>Director/Coordinador:</strong> José Manuel Fernandino Soto (Arquitecto CTAV Nº Colegiado: 01974)</li>
                <li><strong> Coordinador Ambiental:</strong> Luis Quesada Muelas (Geógrafo Col. 2.312)</li>
                <li><strong> Abogada: </strong>Carmen Teresa Benlloch Casabán (ICAV: 9579)</li>
              </ul>
            </div>

            <hr></hr>
            <br></br>
            <p> <strong> Equipo Técnico: </strong> </p>

            <div class="equipo">
              <ul>
                <li>Pedro Castro Alonso, Arquitecto. </li>
                <li>José Sergio Palencia Jiménez, ICCP. </li>
                <li>Iñaki Fernandino Soto, Arquitecto. </li>
                <li>José Vicente Pérez, Sociólogo. </li>
                <li>Juan Garibo Bodí, Arqueólogo. </li>
                <li>Eduardo Vacas Lacruz, Economista. </li>
                <li>Violeta Azuara Checa, Topógrafa. </li>
              </ul>
            </div>




          </Card>

        </div>
        {/* <div style={{ paddingTop: "35px" }}>
          <img width="100%" alt="Ayto" src="./img/ayto.jpeg" />
        </div> */}
      </Drawer>
    </div>
  );
}

export default Legend;
